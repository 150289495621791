import React, { useState } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery, Link } from "gatsby"
import addToMailchimp from "gatsby-plugin-mailchimp"
import Swal from "sweetalert2"

import { Container, IframeContainer } from "../global"

//import HeaderImage from "../../images/product/searching.svg"

const Header = () => {
  const [email, setEmail] = useState("")
  const [error, setError] = useState(false)
  const [check, setCheck] = useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault()
    const validEmail = validateEmail(email)

    setEmail("")
    if (validEmail) {
      Swal.fire(
        "Thank you for subscribing",
        "We will send you an email when we launch the tool",
        "success"
      )
      const result = await addToMailchimp(email)
      console.log(result)
    } else {
      Swal.fire("Error", "Please, enter a valid email", "error")
    }
  }

  const validateEmail = (email) => {
    const valid = /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)

    if (valid) {
      setError(false)
      return true
    } else {
      setError(true)
      return false
    }
  }

  const handleChange = (e) => {
    setEmail(e.target.value)
  }

  const handleCheck = () => {
    setCheck(!check)
  }

  return (
    <HeaderWrapper id="top">
      <Container>
        <Flex>
          <HeaderTextGroup>
            <h1>
              All the web metrics,
              <br />
              one report
            </h1>
            <h2>
              We're building a tool to generate the most completed report about
              a website. Sign up to get early access.
            </h2>
            <HeaderForm onSubmit={handleSubmit}>
              <HeaderInput
                placeholder="Your email"
                value={email}
                onChange={handleChange}
              />
              {check ? (
                <HeaderButton type="submit">Early access</HeaderButton>
              ) : (
                <DisabledButton disabled>Early access</DisabledButton>
              )}
            </HeaderForm>
            <Subtitle>
              <input
                type="checkbox"
                id="consent"
                value={check}
                onChange={handleCheck}
                style={{ width: "24px", marginRight: "0.5em" }}
              />{" "}
              <label for="consent">
                I consent to Wappraiser sending me emails.
              </label>
            </Subtitle>
          </HeaderTextGroup>

          <IframeContainer>
            <iframe
              src="https://www.youtube.com/embed/6I-NUOOqKCQ"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
            {/* <img
                src={HeaderImage}
                style={{ width: "100%", height: "auto" }}
              /> */}
          </IframeContainer>

          <br />
        </Flex>
      </Container>
    </HeaderWrapper>
  )
}

export default Header

const HeaderWrapper = styled.header`
  display: flex;
  min-height: 100vh;
  align-items: center;
  background-color: ${(props) => props.theme.color.primary};
  padding: 160px 0 80px 0;
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
`

const Subtitle = styled.div`
  width: 100%;
  font-size: 14px;
  font-weight: 300;
  display: flex;
  align-items: center;
  color: white;
  letter-spacing: 1px;
`

const HeaderTextGroup = styled.div`
  margin: 0;

  > div {
    width: 100%;
    margin-bottom: -4.5%;
  }

  h1 {
    margin: 0 0 24px;
    color: ${(props) => props.theme.color.white.regular};
  }

  h2 {
    margin-bottom: 24px;
    ${(props) => props.theme.font_size.regular}
    font-weight: normal;
    color: ${(props) => props.theme.color.white.regular};
  }

  p {
    margin-bottom: 48px;
  }
`

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  grid-gap: 114px;
  margin: 2em 0 0 0;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    display: block;
    grid-template-columns: 1fr;
  }
`

const HeaderForm = styled.form`
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    flex-direction: column;
  }
`

const FormSubtitle = styled.span`
  background-color: white;
  padding: 1em;
  font-size: 20px;
  border-radius: 5px;
  display: flex;
  color: red;
  ${(props) => props.theme.font_size.xxsmall}
`

const FormSubtitleLink = styled(Link)`
  color: ${(props) => props.theme.color.secondary};
  padding-bottom: 1px;
  margin-left: 8px;
  text-decoration: none;
  border-bottom: 1px solid ${(props) => props.theme.color.secondary.default};
`

const HeaderInput = styled.input`
  font-weight: 500;
  font-size: 16px;
  color: ${(props) => props.theme.color.black.light};
  line-height: 42px;
  width: 100%;
  text-align: left;
  height: 60px;
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.color.secondary};
  border-image: initial;
  border-radius: 4px;
  padding: 8px 16px;
  outline: 0px;
  &:focus {
    box-shadow: inset ${(props) => props.theme.color.secondary} 0px 0px 0px 2px;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
    margin-bottom: 8px;
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    display: block;
    width: 100%;
  }
`

const HeaderButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${(props) => props.theme.color.secondary};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-left: 0;
  }
`
const DisabledButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  letter-spacing: 1px;
  height: 60px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  white-space: nowrap;
  border-radius: 4px;
  padding: 0px 40px;
  cursor: default;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;

  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-left: 0;
  }
`
